import * as React from 'react';
import Box from '@mui/material/Box';
import { Button } from 'react-bootstrap';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import "./modal.css";
import LogoRFID from "../imagenes/logorfid.png";
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { validate } from 'rut.js';
import EditIcon from '@mui/icons-material/Edit';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BasicModal({ empresa }) {

    const URI = "https://demo.hermescloud.site/web/actualizar_empresa.php"
    const [id, setId] = useState(empresa.idEmpresa)
    const [nombre, setNombre] = useState(empresa.empresa)
    const [rut, setRut] = useState(empresa.rut)
    const [direccion, setDireccion] = useState(empresa.direccion)
    const navigate = useNavigate()
    const [rutError, setRutError] = useState('');
    

    //procedimiento guardar
    const GuardarEditUsuario = async (e) => {
        e.preventDefault(); 
        
        // Verificar si los campos requeridos están vacíos
        if (!nombre || !rut || !direccion) {
            // Mostrar mensaje de error o realizar alguna acción
            alert('Por favor, complete todos los campos');
            return;

        }

        // Validar el campo rut
        if (!validate(rut)) {
            // El RUT no es válido, mostrar mensaje de error o realizar alguna acción
            setRutError('RUT no válido');
            return;
        }
        try {
            // Realizar la solicitud POST
            await axios.post(URI, { id: id, nombre: nombre, rut: rut, direccion: direccion })

            // Mostrar mensaje de éxito
            alert('Usuario actualizado');

            // Cerrar el modal (opcional)
            handleClose();

            // Opcional: Reiniciar los valores del formulario

            // Actualiza la pagina
            window.location.reload();

        } catch (error) {
            // Manejar errores de la solicitud POST, si es necesario
            console.error(error);
        }
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button variant="primary" onClick={handleOpen}><EditIcon /></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Editar Empresa
                    </Typography>

                    <Divider variant="middle" />
                    <form onSubmit={GuardarEditUsuario}>
                        <div className='input-container'>
                            <div>
                                <div className='input-container'>
                                    <TextField
                                        className='input-text'
                                        id="LabelModalRut"
                                        label="Rut"
                                        variant="outlined"
                                        defaultValue={empresa.rut}
                                        onChange={(e) => setRut(e.target.value)}
                                        
                                        error={rutError !== ''}
                                        helperText={rutError}
                                    />
                                </div>
                                <div className='input-container'>
                                    <TextField className='input-text'
                                        id="LabelModalNombre"
                                        label="Nombre"
                                        variant="outlined"
                                        defaultValue={empresa.empresa}
                                        onChange={(e) => setNombre(e.target.value)} />

                                </div>
                                <div className='input-container'>
                                    <TextField className='input-text'
                                        id="LabelModalContrasena"
                                        label="Dirección"
                                        variant="outlined"
                                        defaultValue={empresa.direccion}
                                        onChange={(e) => setDireccion(e.target.value)} />
                                </div>
                                <div className="input-container">
                                    <Button type='submit' className='btn btn-primary'>Guardar</Button>
                                    <Button className="boton-container" variant="outlined">Cancelar</Button>
                                </div>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div>
                                <img className='tamano-imagen' src={LogoRFID} />
                            </div>
                        </div>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}