import React, { useState } from 'react';
import { Button, Modal, Form, Table } from 'react-bootstrap';

const apiUrlZebra = 'http://localhost/zebra.php'; // Asegúrate de que la URL sea correcta y accesible
const apiUrlCodigoSE = 'https://demo.hermescloud.site/web/CodigoSE.php'; // Asegúrate de que la URL sea correcta y accesible

export default function ModalImpresionSinEspecificar() {
    const [show, setShow] = useState(false);
    const [cantidadProductos, setCantidadProductos] = useState(1);
    const [codigos, setCodigos] = useState(['SE0000000001']); // Inicializa con un código por defecto
    const [loading, setLoading] = useState(false); // Estado para manejar la carga

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const generateSequentialCodes = (cantidad, startCode = 'SE0000000001') => {
        const startNumber = parseInt(startCode.slice(2), 10);
        return Array.from({ length: cantidad }, (_, i) =>
            'SE' + String(startNumber + i).padStart(10, '0')
        );
    };

    const handleCantidadChange = (e) => {
        const cantidad = parseInt(e.target.value);
        setCantidadProductos(cantidad);
        setCodigos(generateSequentialCodes(cantidad)); // Genera códigos secuenciales
    };

    const handleImprimir = async () => {
        setLoading(true); // Activamos el estado de carga

        try {
            // Datos para enviar a CodigoSE
            const dataCodigoSE = {
                codigos: codigos.map(codigo => ({
                    codigo: codigo
                }))
            };

            console.log("Datos enviados a CodigoSE:", JSON.stringify(dataCodigoSE));

            // Enviar solicitud a CodigoSE
            const responseCodigoSE = await fetch("https://demo.edgarleal.es/web/CodigoSE.php", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataCodigoSE)
            });

            if (!responseCodigoSE.ok) {
                throw new Error('Error al enviar a CodigoSE: ' + responseCodigoSE.statusText);
            }

            const responseDataCodigoSE = await responseCodigoSE.json();
            console.log("La solicitud POST a CodigoSE fue exitosa.");
            console.log("Respuesta de CodigoSE:", responseDataCodigoSE);

            // Datos para enviar a Zebra
            const dataZebra = {
                modelos: codigos.map(codigo => ({
                    producto: "Sin Descripcion",
                    codigo: codigo
                }))
            };

            console.log("Datos enviados a Zebra:", JSON.stringify(dataZebra));

            // Enviar solicitud a Zebra
            const responseZebra = await fetch(apiUrlZebra, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataZebra)
            });

            if (!responseZebra.ok) {
                throw new Error('Error al enviar a Zebra: ' + responseZebra.statusText);
            }

            const responseDataZebra = await responseZebra.json();
            console.log("La solicitud POST a Zebra fue exitosa.");
            console.log("Respuesta de Zebra:", responseDataZebra);

            alert('Etiquetas impresas con éxito'); // Mensaje de éxito para el usuario

            setLoading(false); // Desactivamos el estado de carga
            handleClose(); // Cerrar el modal

        } catch (error) {
            console.error('Hubo un problema con la operación Fetch:', error);
            alert('Hubo un problema al imprimir las etiquetas'); // Mensaje de error para el usuario
            setLoading(false); // Desactivamos el estado de carga en caso de error
        }
    };

    return (
        <div>
            <Button variant="primary" onClick={handleShow}>
                Imprimir etiquetas sin especificar
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ marginTop: '50px', marginLeft: '50px' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Imprimir etiquetas sin especificar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formCantidad">
                            <Form.Label>Seleccione la cantidad de etiquetas a imprimir</Form.Label>
                            <Form.Control type="number" value={cantidadProductos} onChange={handleCantidadChange} />
                        </Form.Group>

                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {codigos.map((codigo, index) => (
                                        <tr key={index}>
                                            <td>{codigo}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleImprimir} disabled={loading}>
                        {loading ? 'Imprimiendo...' : 'Imprimir'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
